<template>
  <div class="notifications">
    <headerTop title="站内信" />
    <div class="head center">
      <div class="center" @click="handleReadAll">全部已读</div>
      <div class="center" @click="handleRemoveAll">删除已读</div>
    </div>
    <div class="main" v-if="messageList?.length">
      <div @click="handleRead(item)" v-for="(item, index) in messageList" :key="index" class="main_top space_between">
        <span class="dot" v-if="item.status == 0"></span>
        <div class="content">{{ item.message }}</div>
        <div class="time" v-if="item.updateTime">{{ item.updateTime.split(' ')[0] }}</div>
      </div>
    </div>
    <div class="not_data" v-else><span>--暂无消息--</span></div>
    <div class="pageControl">
      <div :class="page.pageNum == 1 ? 'left disable' : 'left'" @click="subPage">←</div>
      <div class="right" @click="addPage">→</div>
    </div>

    <el-dialog title="消息详情" :modal-append-to-body="false" append-to-body class="notifications_dialog" :visible.sync="showDetail" :close-on-click-modal="false" @close="handleClose">
      <article class="message">{{ readMsg.message }}</article>
      <div slot="footer" class="dialog_footer">
        <span class="delete" @click="handleRemove">删除</span>
        <span class="confirm" @click="handleClose">确定</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMessageList, readMessage, removeMessage } from '@/api/index';
export default {
  data() {
    return {
      readMsg: {},
      messageList: [],
      showDetail: false,
      showdelete: false,
      showdeleteAll: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      disAdd: false,
    };
  },
  mounted() {
    this.queryMessageList();
  },
  methods: {
    subPage() {
      if (this.page.pageNum == 1) {
        return false;
      } else {
        this.page.pageNum--;
        this.queryMessageList();
      }
    },
    addPage() {
      this.page.pageNum++;
      this.queryMessageList();
    },
    queryMessageList() {
      getMessageList(this.page).then(({ data }) => {
        if (data.data.data.rows.length != 0) {
          this.messageList = data?.data?.data?.rows || [];
        }else{
          console.log(1);
          
          this.page.pageNum--;
        }

        // if (this.messageList.length < this.page.pageSize) {
        //   this.page.pageNum--;
        // }
      });
    },
    handleRemove() {
      this.playAudio();
      this.$confirm('确定要删除这条信息吗?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        cancelButtonClass: 'active_btn',
        center: true,
      })
        .then(() => {
          this.playAudio();
          removeMessage(this.readMsg.id).then(() => {
            this.showDetail = false;
            this.queryMessageList();
          });
        })
        .catch(() => {
          this.playAudio();
        });
    },
    handleRemoveAll() {
      this.playAudio();
      this.$confirm('确定要删除已读信息吗?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        cancelButtonClass: 'active_btn',
        center: true,
      })
        .then(() => {
          const list = this.messageList.filter(item => item.status == 1).map(item => item.id);
          removeMessage(list).then(() => {
            this.queryMessageList();
          });
        })
        .catch(() => {
          this.playAudio();
        });
    },
    handleReadAll() {
      this.playAudio();
      const list = this.messageList.filter(item => item.status == 0).map(item => item.id);
      readMessage(list).then(() => {
        this.queryMessageList();
      });
    },
    handleRead(item) {
      this.playAudio();
      this.readMsg = item;
      this.showDetail = true;
      if (item.status != 0) return;
      readMessage(item.id).then(() => {
        this.queryMessageList();
      });
    },
    handleClose() {
      this.playAudio();
      this.showDetail = false;
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
  },
};
</script>

<style lang="less" scoped>
.notifications {
  margin: 0 @pcSpace;
  @media @max750 {
    margin: 0 14px;
  }
  .head {
    justify-content: end;
    @media @max750 {
      justify-content: space-between;
    }
    div {
      color: @main;
      font-size: 16px;
      cursor: pointer;
      margin-left: 30px;
      @media @max750 {
        margin-left: 0;
        font-size: 14px;
      }
    }
  }
  .main {
    width: 100%;
    margin-top: 14px;
    @media @max750 {
      margin-top: 10px;
    }
    .main_top {
      width: 100%;
      height: 60px;
      padding: 0 12px;
      margin: 14px 0;
      position: relative;
      border-radius: 10px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      background: rgba(0, 0, 0, 0.2);
      @media @max750 {
        height: 50px;
        margin-top: 10px;
      }
      .dot {
        top: -4px;
        right: -4px;
        .wh(10px, 10px);
        border-radius: 50%;
        position: absolute;
        background: red;
        .flex-a-center;
        @media @max750 {
          top: -2px;
          right: -2px;
          .wh(8px, 8px);
        }
      }
      .content {
        .ell;
        flex: 1;
        .sc(16px, #fff);
      }
      .time {
        padding-left: 20px;
        .sc(16px, #999);
        @media @max750 {
          padding-left: 10px;
        }
      }
    }
  }
  .pageControl {
    margin: 10px auto 0;
    display: flex;
    justify-content: center;
    .disable {
      border: 1px solid #505252 !important;
      color: #505252 !important;
    }
    .left,
    .right {
      width: 60px;
      height: 40px;
      border: 1px solid #00f9ff;
      color: #00f9ff;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      border-radius: 15px;
      cursor: pointer;
    }
  }
  .over_text {
    padding: 20px 0;
  }
}

.notifications_dialog {
  .flex-center;
  /deep/ .el-dialog {
    width: 1260px;
    background: @bg1;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 0 !important;
    border: 1px solid #404448;
    @media @max750 {
      margin: 0 20px;
    }
    .el-dialog__header {
      padding: 14px 0;
      text-align: center;
      background: #0d1214;
      .el-dialog__title {
        .sc(24px, #fff);
        @media @max750 {
          font-size: 18px;
        }
      }
      .el-dialog__headerbtn {
        top: 16px;
        right: 16px;
      }
    }
    .el-dialog__body {
      padding: 0;
      .message {
        padding: 20px;
        min-height: 20vh;
        max-height: 50vh;
        overflow-y: auto;
        .sc(18px, #fff);
        line-height: 30px;
        box-sizing: border-box;
        @media @max750 {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
    .el-dialog__footer {
      padding: 0;
      .dialog_footer {
        width: 100%;
        height: 70px;
        .flex-center;
        padding: 0 20px;
        background: #0d1214;
        box-sizing: border-box;
        @media @max750 {
          height: 50px;
        }
        .confirm,
        .delete {
          .sc(16px, #ccc);
          margin: 0 20px;
          border-radius: 4px;
          .btn-style(100px, 36px);
          @media @max750 {
            margin: 0 10px;
            font-size: 14px;
            border-radius: 4px;
            .btn-style(80px, 30px);
          }
        }
        .confirm {
          color: @main;
          border-color: @main;
        }
      }
    }
    .close_btn {
      .wh(140px, 40px);
      .sc(18px, #fff);
      text-align: center;
      margin: 40px auto;
      line-height: 40px;
      border-radius: 20px;
      background: #19373a;
      .btn-shadow;
      @media @max750 {
        font-size: 14px;
        .wh(100px, 30px);
        line-height: 30px;
        margin: 20px auto;
      }
    }
  }
}
</style>
